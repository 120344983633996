import React from 'react';

const TermsOfService = ({ closeTOS }) => {
    return (
        <>
            <div onClick={closeTOS} className="mb-8 text-gray-900 hover:text-blue-700 ">
                <div className="flex items-start cursor-pointer">

                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>

                Zurück</div></div>
            <h1 className="text-xl font-bold text-gray-900 mb-4 mt-4">Datenschutzerklärung</h1>
            <div className="text-gray-600">
            <h2 className="text-l font-bold text-gray-900 mb-4 mt-8">
            1. Verantwortliche Stelle und allgemeine Datenschutzgrundsätze
            </h2>

                KMU Personal AG
                <br />
                Hauptstrasse 45
                <br />
                8280 Kreuzlingen
                <br />
                <br />
vertreten durch Massimo Bella
<br />
                <br />
Telefon: +41 71 666 89 90
<br />Mail: info@kmupersonal.ch<br />Website: kmupersonal.ch
<br />
                <br />
(nachfolgend auch „KMU Personal“, „wir“, „uns“ genannt)
<br />
                <br />
ist, als Betreiber der Website kmupersonal.ch verantwortlich für den Datenschutz hinsichtlich der EU-Datenschutz-Grundverordnung (DSGVO).
<br />
                <br />
Bei der Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten ist uns die Wahrung Ihrer Privatsphäre und Einhaltung der Datenschutz Anforderungen ein besonderes Anliegen. Ihre personenbezogenen Daten werden daher ausschließlich unter Beachtung der datenschutzrechtlichen Vorschriften erhoben, verarbeitet und genutzt.
Als verantwortliche Stelle ist es uns wichtig, dass Sie im Rahmen der Nutzung unserer Website jederzeit auf die Einhaltung des Datenschutzes vertrauen können. Die nachfolgenden Hinweise geben Ihnen Auskunft welche personenbezogenen Daten wir während Ihres Besuchs auf der Website erheben, wie wir diese verarbeiten und nutzen. Zudem möchten wir Sie darüber informieren, welche Maßnahmen wir treffen, um Ihre personenbezogenen Daten zu schützen.
<br />
                <br />
Diese Datenschutzerklärung gilt nur für unsere Website. Insbesondere beziehen sich die folgenden Hinweise nicht auf fremde Websites anderer Anbieter, auf die von unserer Website aus verlinkt wird oder – umgekehrt – von denen aus auf unsere Website verlinkt wird.
<br /><br />
            <h2 className="text-l font-bold text-gray-900 mb-4 mt-4">
            2. Erhebung und Verwendung Ihrer personenbezogenen Daten </h2>
Personenbezogene Daten sind Angaben über persönliche und sachliche Verhältnisse einer bestimmten oder bestimmbaren natürlichen Person. Zu Ihren personenbezogenen Daten zählen folglich sämtliche Daten, die eine Identifikation Ihrer Person zulassen, wie bspw. Ihr Name, Ihre Anschrift, Ihre Telefonnummer oder Ihre E-Mail-Adresse.
<br /><br />
Personenbezogene Daten werden durch uns nur erhoben, verarbeitet und genutzt, wenn und soweit Sie uns die betreffenden Daten für die konkrete Datenerhebung bzw. Datenverwendung freiwillig zur Verfügung gestellt haben, eine Rechtsvorschrift die konkrete Datenerhebung und/oder -verwendung erlaubt oder Sie in diese zuvor ausdrücklich eingewilligt haben.
<br /><br />
Da wir unseren Sitz in der Schweiz haben und unsere Website von dort aus betrieben wird, findet die Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten, soweit sich nicht aus dieser Datenschutzerklärung etwas anderes ergibt, prinzipiell in der Schweiz statt. Im Einzelnen erheben, verarbeiten und nutzen wir Ihre personenbezogenen Daten für folgende Zwecke:
<br /><br />
Informatorische Nutzung unserer Website: Server-Logfiles
<br /><br />
Wenn Sie unsere Website besuchen, werden über Ihren Browser die folgenden Daten übertragen und temporär in sogenannten Server-Logfiles gespeichert:
<br /><br />
–  Name der aufgerufenen Website– Datei
<br />
–  Datum und Uhrzeit des Abrufs
<br />
–  übertragene Datenmenge
<br />
–  Meldung über erfolgreichen Abruf
<br />
–  Browsertyp nebst Version
<br />
–  das Betriebssystem des Nutzers
<br />
–  die zuvor besuchte Seite
<br />
–  Ihre IP-Adresse und der anfragende Provider.

<br /><br />

Wir verwenden die Protokolldaten ohne Zuordnung zur Person des Nutzers oder sonstiger Profilerstellung nur für die statistischen Auswertungen zum Zwecke des Betriebs, der Sicherheit und der Optimierung unserer Website.
<br /><br />
Eine Verarbeitung der personenbezogenen Daten, welche ausschliesslich über die Website erhoben wird, wird nicht in Form eines Nutzer-Profilings durchgeführt.
<br /><br />
Die Erhebung, Verarbeitung (bzw. Übermittlungen) und Nutzung eben genannter personenbezogener Daten erfolgt gemäss folgender Rechtslage:
<br /><br />
Art. 6 Abs. 1 S. 1 lit. f DSGVO, berechtigtes Interesse (das berechtigte Interesse folgt aus dem obengenannten Zweck)
<br /><br />
 

Informatorische Nutzung unserer Website: Cookies
<br /><br />
Wenn Sie unsere Website besuchen, kann es sein, dass wir Informationen in Form eines Cookies bzw. mit einer den Cookies vergleichbaren Technologie auf dem von Ihnen verwendeten Computer oder mobilen Gerät ablegen (nachfolgend einheitlich: „Cookies“).
<br /><br />
Das Akzeptieren von derartigen Cookies ist keine Voraussetzung zum Besuch unserer Website. Jedoch weisen wir darauf hin, dass einige Funktionen nur möglich sind, wenn Sie erlauben, Cookies zu setzen.
<br /><br />
Was sind Cookies?<br />
Cookies sind kleine Dateien, die auf Ihrem Datenträger gespeichert werden und die bestimmte Einstellungen und Daten zum Austausch mit unserem System über Ihren Browser speichern. Grundsätzlich unterscheidet man zwei verschiedene Arten von Cookies, sogenannte Session-Cookies, die gelöscht werden, sobald Sie Ihren Browser schließen und temporäre bzw. permanente Cookies, die für einen längeren Zeitraum oder unbegrenzt auf Ihrem Datenträger gespeichert werden. Diese Speicherung hilft uns, unsere Website sowie die darüber angebotenen Dienste für Sie entsprechend zu gestalten. Zudem erleichtert Ihnen dies die Nutzung unserer Website.
<br /><br />
Welche Daten werden in den Cookies gespeichert?<br />
In den von uns verwendeten Cookies werden keine personenbezogenen Daten gespeichert. Die von uns verwendeten Cookies sind damit nicht direkt einer bestimmten Person und damit auch nicht direkt Ihnen zuordenbar. Bei Aktivierung des Cookies wird diesem eine Identifikationsnummer zugewiesen. Eine Zuordnung Ihrer personenbezogenen Daten wird von uns nicht vorgenommen. Auf Basis der Cookie-Technologie erhalten wir allenfalls pseudonymisierte Informationen, beispielsweise darüber, welche unserer Seiten besucht und wie die angebotenen Dienste genutzt wurden.

Wie kann ich die Speicherung von Cookies verhindern?<br />
In Ihrem Browser können Sie einstellen, dass eine Speicherung von Cookies nur durch Ihre Zustimmung akzeptiert wird. Auf diese Weise können Sie, wenn Sie die entsprechende Zustimmung verweigern, auch der Verwendung unserer Cookies widersprechen.
<br /><br />
Wenn Sie nicht möchten, dass wir den von Ihnen verwendeten Computer oder Ihr mobiles Gerät wiedererkennen, können Sie das Speichern von Cookies auf Ihrem Datenträger verhindern, indem Sie in Ihren Browser-Einstellungen „keine Cookies akzeptieren“ wählen. Wie das im Einzelnen funktioniert, entnehmen Sie bitte der Anleitung Ihres Browser-Herstellers.
<br /><br />
Die Erhebung, Verarbeitung (bzw. Übermittlungen) und Nutzung eben genannter personenbezogener Daten erfolgt gemäss folgender Rechtslage:
<br /><br />
Art. 6 Abs. 1 S. 1 lit. f DSGVO, berechtigtes Interesse (das berechtigte Interesse erfolgt daraus, dass nur durch eine entsprechende Verarbeitung, die vom Nutzer gewünschten Handlung (Abwicklung des Bewerbungsverfahrens) durchgeführt werden kann.)
<br /><br />
Kommunikation per Kontaktformular oder E-Mail
<br /><br />
Wenn Sie uns über ein Kontaktformular oder direkt per E-Mail kontaktieren, erfolgt die Erhebung, Verarbeitung und Nutzung Ihrer freiwillig mitgeteilten Kontaktdaten (wie bspw. Name, E-Mail-Adresse) nur zweckgerichtet, entweder zur Aufnahme und ggf. Beantwortung Ihrer Anfrage(n) sowie zur technische Administration gemäss folgender Rechtslage:
<br /><br />
Art. 6 Abs. 1 S. 1 lit. f DSGVO, berechtigtes Interesse (das berechtigte Interesse erfolgt daraus, dass nur durch eine entsprechende Verarbeitung, die vom Nutzer gewünschten Handlung (Beantwortung von Kontaktanfragen) durchgeführt werden kann.)
<br /><br />
Online-Bewerbungsanfragen
<br /><br />
Ihre Bewerbungsdaten werden zum Zwecke der Abwicklung des Bewerbungsverfahrens elektronisch von uns erhoben und verarbeitet. Folgt auf Ihre Bewerbung der Abschluss eines Anstellungsvertrages, so können Ihre übermittelten Daten zum Zwecke des üblichen Organisations- und Verwaltungsprozesses unter Beachtung der einschlägigen rechtlichen Vorschriften von uns in Ihrer Personalakte gespeichert werden.
<br /><br />
Die Erhebung, Verarbeitung (bzw. Übermittlungen) und Nutzung eben genannter personenbezogener Daten erfolgt gemäss folgender Rechtslage:
<br /><br />
Art. 6 Abs. 1 S. 1 lit. f DSGVO, berechtigtes Interesse (das berechtigte Interesse erfolgt daraus, dass nur durch eine entsprechende Verarbeitung, die vom Nutzer gewünschten Handlung (Abwicklung des Bewerbungsverfahrens) durchgeführt werden kann.)
<br /><br />
Newsletter Anmeldungen
<br /><br />
Wenn Sie sich für unseren Newsletter über das entsprechende Formular anmelden, senden wir an die angegebene E-Mail-Adresse umgehend ein E-Mail, welche einen Link enthält. Indem Sie auf diesen Link klicken, bestätigen Sie Ihre Newsletter-Anmeldung im sogenannten Double-Opt-In-Verfahren. Zudem erteilen Sie hiermit die Einwilligung zur Speicherung Ihrer E-Mail-Adresse einschliesslich Datum der Eintragung und IP-Adresse.
<br /><br />
Wir verwenden Ihre E-Mail-Adresse und die gleichzeitig erhobenen Personendaten wie Vorname, Name und E-Mail-Adresse nur für die Verwaltung und die Zusendung unseres Newsletters. Unsere Newsletters enthalten keine offensichtlichen oder versteckten Zähler, Werbung Dritter oder Verknüpfungen auf fremde Seiten, die nicht direkt mit dem Inhalt unseres Newsletters im Zusammenhang stehen.
<br /><br />
Sollten Sie zu einem späteren Zeitpunkt keine Newsletter mehr von uns erhalten wollen, können Sie Ihre Einwilligung jederzeit selbstständig, in jedem Newsletter über den Abmelde Link, widerrufen.
<br /><br />
Für die Newsletter Anmeldung und den Versand verwenden wir die Newsletter-Software Mailchimp. Mehr Informationen hierzu finden Sie in Kapitel 3.2.
<br /><br />
Die Erhebung, Verarbeitung (bzw. Übermittlungen) und Nutzung eben genannter personenbezogenen Daten erfolgt gemäss folgender Rechtslage:
<br /><br />
Art. 6 Abs. 1 S. 1 lit. f DSGVO, berechtigtes Interesse (das berechtigte Interesse erfolgt daraus, dass nur durch eine entsprechende Verarbeitung, die vom Nutzer gewünschten Handlung (Zustellung des Newsletters zu informationszwecken) durchgeführt werden kann.)
<br /><br />
  
<h2 className="text-l font-bold text-gray-900 mb-4 mt-4">
3. Einbindung und Nutzung von Drittanbieter-Diensten
</h2>
Google Analytics<br />
<br />
Was ist Google Analytics und welche Daten werden dadurch erfasst?
<br /><br />
Unsere Website verwenden Google Analytics, einen Webanalysedienst der Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA („Google“). Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse des Website Besucherverhaltens und der Websitenutzung ermöglichen. Die durch die Cookies erzeugten Informationen über Ihre Benutzung der Webseite wie
<br /><br />
Browser-Typ/-Version,<br />
verwendetes Betriebssystem,<br />
Referrer-URL (die zuvor besuchte Seite),<br />
Hostname des zugreifenden Rechners (IP-Adresse),<br />
Uhrzeit der Serveranfrage,<br />
<br />
werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Wir haben zudem auf unserer Website Google Analytics mit dem Code „anonymizeIP“ erweitert. Dies garantiert die Maskierung Ihrer IP-Adresse, sodass alle Daten anonym erhoben werden.
<br /><br />
Google hat sich mit einer Privacy-Shield-Zertifizierung zur Einhaltung des vom US-Handelsministerium veröffentlichten Privacy-Shield-Abkommens zwischen der EU und den USA über die Erhebung, Nutzung und Speicherung von personenbezogenen Daten aus den Mitgliedsstaaten der EU verpflichtet. Google wird die genannten Informationen verwenden, um Ihre Nutzung unserer Website auszuwerten, um Reports über die Webseitenaktivitäten für uns zusammenzustellen und um weitere mit der Nutzung von Webseiten und Internet verbundene Dienstleistungen an uns zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Eine Übertragung dieser Daten durch Google an Dritte findet nur aufgrund gesetzlicher Vorschriften oder im Rahmen der Auftragsdatenverarbeitung statt.
<br /><br />
Wie kann ich die Datenerfassung und -verarbeitung durch Google Analytics verhindern?
<br /><br />
Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihres Browsers verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall möglicherweise nicht sämtliche Funktionen dieser Webseite vollumfänglich nutzen können.
<br /><br />
Sie können darüber hinaus die Datenerfassung und -verarbeitung durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: http://tools.google.com/dlpage/gaoptout?hl=de.
<br /><br />
Alternativ zum Browser-Add-On, insbesondere bei Browsern auf mobilen Endgeräten, können Sie die Erfassung durch Google Analytics zudem verhindern, indem Sie auf den folgenden Link klicken:Google Analytics deaktivieren
<br /><br />
Es wird ein Opt-Out-Cookie gesetzt, das die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website verhindert. Der Opt-Out-Cookie gilt nur in diesem Browser und nur für unsere Website und wird auf Ihrem Gerät abgelegt. Löschen Sie die Cookies in diesem Browser, müssen Sie das Opt-Out-Cookie erneut setzen.
<br /><br />
Nähere Informationen zu Google Analytics und Datenschutz finden Sie unter: https://support.google.com/ analytics/answer/6004245?hl=de.
<br /><br />
Die Erhebung, Verarbeitung (bzw. Übermittlungen) und Nutzung eben genannter personenbezogenen Daten erfolgt gemäss folgender Rechtslage:
<br /><br />
Art. 6 Abs. 1 S. 1 lit. f DSGVO, berechtigtes Interesse (das berechtigte folgt aus den oben genannten Zwecken (insbesondere Optimierung der Webseitennutzung und Verbesserung der Benutzerfreundlichkeit)).
<br /><br />
MailChimp
<br /><br />
Wir verwenden für die Anmeldung und den Versand unseres Newsletters die Newsletter-Software MailChimp, ein Dienst der The Rocket Science Group, LLC 675 Ponce de Leon Ave NE Suite 5000 Atlanta, GA 30308 USA.
<br /><br />
Die bei der Newsletter Anmeldung via Double-Opt In gespeicherten Daten werden verschlüsselt an MailChimp übermittelt und gespeichert. Nach der Registrierung sendet Ihnen MailChimp eine E-Mail, um Ihre Anmeldung zu bestätigen. Des Weiteren bietet MailChimp verschiedene Analysemöglichkeiten darüber, wie die versandten Newsletter geöffnet und benutzt werden, z.B. an wie viele Nutzer eine E-Mail versendet wurde, ob E-Mails zurückgewiesen wurden und ob sich Nutzer nach Erhalt einer E-Mail von der Liste abgemeldet haben. Diese Analysen sind jedoch nur Gruppenbezogen und werden von uns nicht zur individuellen Auswertung verwendet. MailChimp verwendet darüber hinaus das Analysetool Google Analytics und bindet es unter Umständen in die Newsletter ein.
<br /><br />
Weitere Details zum Datenschutz von MailChimp:
www.mailchimp.com/legal/privacy/.<br />
<br /><br />
Die Erhebung, Verarbeitung (bzw. Übermittlungen) und Nutzung eben genannter personenbezogenen Daten erfolgt gemäss folgender Rechtslage:
<br /><br />
Art. 6 Abs. 1 S. 1 lit. f DSGVO, berechtigtes Interesse (das berechtigte Interesse erfolgt daraus, dass nur durch eine entsprechende Verarbeitung, die vom Nutzer gewünschten Handlung (Zustellung des Newsletters zu informationszwecken) durchgeführt werden kann.)
<br /><br />
 
<h2 className="text-l font-bold text-gray-900 mb-4 mt-4">
4. Werden personenbezogene Daten mit Dritten geteilt?
</h2>
<br />
Wir geben die personenbezogenen Daten der Nutzer grundsätzlich nicht an Dritte weiter, soweit sich aus dieser Datenschutzerklärung nicht etwas anderes ergibt.
<br /><br />
Wir geben personenbezogenen Daten des Nutzers ggf. an Dritte weiter, wenn wir dazu gesetzlich verpflichtet sind (z.B. auf Anfrage eines Gerichts oder einer Strafverfolgungsbehörde). Rechtsgrundlage für eine solche Datenverarbeitung ist Art. 6 Abs. 1 Satz 1 lit. c DSGVO (rechtliche Verpflichtung).
<br /><br />
 
<h2 className="text-l font-bold text-gray-900 mb-4 mt-4">
5. Wie lange werden personenbezogene Daten der Nutzer gespeichert?</h2>
Eine Löschung Ihrer personenbezogenen Daten erfolgt, wenn die Daten zur Erfüllung des mit der Speicherung verfolgten Zwecks nicht mehr erforderlich sind, sofern gesetzliche Aufbewahrungspflichten dem nicht entgegenstehen, oder wenn ihre Speicherung aus sonstigen gesetzlichen Gründen unzulässig ist. In diesen Fällen können Sie auch einen Löschungsanspruch geltend machen. An die Stelle einer Löschung kann eine Sperrung treten, soweit einer Löschung gesetzliche oder vertragliche Aufbewahrungsfristen entgegenstehen, Grund zu der Annahme besteht, dass durch eine Löschung schutzwürdige Interessen beeinträchtigt würden oder eine Löschung wegen der besonderen Art der Speicherung nicht oder nur mit unverhältnismäßig hohem Aufwand möglich ist.
<br /><br />
 
<h2 className="text-l font-bold text-gray-900 mb-4 mt-4">6. Datensicherheit</h2>
Wir treffen technische und organisatorische Sicherheitsvorkehrungen, um Ihre personenbezogenen Daten gegen zufällige oder vorsätzliche Manipulationen, Verlust, Zerstörung oder gegen den Zugriff unberechtigter Personen zu schützen. Unsere Datenverarbeitung und unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.
<br />
Ihre im Rahmen der Registrierung übertragenen persönlichen Daten werden bei uns sicher durch Verschlüsselung übertragen. Dies gilt für den Registrierungsvorgang und auch für das Kundenlogin. Wir bedienen uns dabei des Codierungssystems SSL (Secure Socket Layer). Zwar kann niemand einen absoluten Schutz garantieren. Wir sichern unsere Website und sonstige Systeme jedoch durch technische und organisatorische Maßnahmen gegen Verlust, Zerstörung, Zugriff, Veränderung oder Verbreitung Ihrer Daten durch unbefugte Personen ab.
<br />
Bitte beachten Sie jedoch, dass insbesondere bei einer Kommunikation per Kontaktfunktion und E-Mail keine vollständige Datensicherheit von uns gewährleistet werden kann. Insbesondere im Falle von vertraulichen Informationen empfehlen wir ihnen daher den Versand einen sicheren Übertragungsweg, wie etwa per Briefpost.
<br />
Unsere Beschäftigten sind auf das Datengeheimnis verpflichtet.

<br /><br />

<h2 className="text-l font-bold text-gray-900 mb-4 mt-4">7. Welche Rechte haben Nutzer?</h2>
Nutzer haben das Recht, von uns jederzeit Auskünfte zu verlangen über die zu ihnen bei uns gespeicherten personenbezogenen Daten. Soweit die gesetzlichen Voraussetzungen vorliegen, haben Nutzer gegenüber uns ferner Rechte auf Berichtigung, Löschung bzw. Einschränkung der Verarbeitung der entsprechenden personenbezogenen Daten, das Recht der Verarbeitung ihrer personenbezogenen Daten durch uns zu widersprechen sowie darauf, dass sie die sie betreffenden personenbezogenen Daten, die sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format von uns erhalten (Nutzer können diese Daten an andere Stellen übermitteln oder übermitteln lassen).
<br />
Wenn Nutzer eine Einwilligung zur Nutzung von personenbezogenen Daten erteilt haben, können Sie diese jederzeit für die Zukunft widerrufen.
<br />
Wenn Nutzer der Ansicht sind, dass die Verarbeitung der sie betreffenden personenbezogenen Daten durch uns gegen das anwendbare Datenschutzrecht verstößt, können sie sich bei der zuständigen Aufsichtsbehörde für den Datenschutz beschweren.
<br />
An wen kann ich mich wenden?
<br />
Nutzer können mit uns unter der in Kapitel 1 angegebenen Adresse sowie über info@kmupersonal.ch in Kontakt treten.
<br /><br />
 

<h2 className="text-l font-bold text-gray-900 mb-4 mt-4">8. Änderungen der Datenschutzerklärung</h2>
Von Zeit zu Zeit kann es erforderlich werden, den Inhalt der vorliegenden Datenschutzerklärung anzupassen. Wir behalten uns daher vor, diese jederzeit zu ändern. Wir werden die geänderte Version der Datenschutzerklärung ebenfalls an dieser Stelle veröffentlichen. Wenn Sie unsere Website wieder besuchen, sollten Sie sich daher die Datenschutzerklärung erneut durchlesen.
<br /><br />
Version 1.0, Stand 23.05.2018
</div>
        </>
    )
}

export default TermsOfService;