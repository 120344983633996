import React, { useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/de'
import TermsOfService from './TermsOfService';
import {
  Formik,
  Form,
  Field,
  ErrorMessage
} from 'formik'

const SignUpForm = () => {
  const [isTransmitted] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isTOSvisible, setTOSvisible] = useState(false);

  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  const sendEmail = (salutation, firstName, lastName, birthday, mobile, email, job, isOpenForOtherJobs, comment) => {
    moment.locale('de')
    birthday = moment(birthday).format('L')
    axios.post('/.netlify/functions/send-contact-email', {
      salutation, firstName, lastName, birthday, mobile, email, job, isOpenForOtherJobs, comment
                  })
    // axios.post('/.netlify/functions/send-contact-email', {
    //   saluation,
    //   firstName,
    //   lastName,
    //   birthday,
    //   mobile,
    //   email,
    //   job,
    //   isOpenForOtherJobs,
    //   comment
    // })
  }

  // const handleSubmit = e => {
  //   e.preventDefault();

  //   fetch("/", {
  //     method: "POST",
  //     headers: { "Content-Type": "application/x-www-form-urlencoded" },
  //     body: encode({ "form-name": "contact", firstName, lastName })
  //   })
  //     .then(() => {
  //       axios.post('/.netlify/functions/send-contact-email', {
  //               firstName,
  //               lastName,
  //               contactEmail: "json@bluewin.ch",
  //               message: "Neue Anmeldung!",
  //             })
  //               .then(res => {
  //                 console.log(res);
  //                 console.log(res.data);
  //                 setSuccess(true);
  //               })
  //     })
  //     .catch(error => alert(error));
  //   setSuccess(true);
  // };

  const showTOS = () => {
    setTOSvisible(true);
    // scroll to the top of the page
    window.scrollTo(0, 0);
  }

  const closeTOS = () => {
    setTOSvisible(false);
    // scroll to the top of the page
    window.scrollTo(0, 0)
  }

  return (
    <div className="min-h-screen sm:pb-20 sm:pt-20 flex items-center flex-col bg-gray-100">
      <div className="min-h-screen sm:min-h-0 bg-white shadow sm:rounded-lg max-w-screen-sm">
        <div className="px-10 py-10 sm:px-10">
          <div className="w-1/3 sm:w-1/4 mb-10">
            <img src={process.env.PUBLIC_URL + '/kmu-personal-black.png'} alt="company logo" />
          </div>
          {isTOSvisible &&
            <TermsOfService closeTOS={closeTOS} />
          }
          {success &&
            <div>
              <div className="flex items-center flex-col ">
                <div className="w-1/3 mt-6">
                  <img src={process.env.PUBLIC_URL + '/success.png'} alt="successful transmitted" />
                </div>
                <div>
                  <h1 className="text-3xl font-bold leading-9 text-gray-900 text-center sm:truncate mb-8 mt-8">
                    Erfolgreich registriert!
                  </h1>
                  <div className="text-gray-600 text-lg text-center mb-5 max-w-sm">
                    Wir haben Ihre Registrierung erhalten und werden uns bei Ihnen melden.
                  </div>
                </div>
              </div>
            </div>
          }

          <>
            {(!isTransmitted && !isTOSvisible && !success) &&
              <>
                <h1 className="text-3xl font-bold leading-9 text-gray-900 sm:truncate mb-4">
                  Jetzt registrieren <br />und Jobangebote erhalten
              </h1>
                <div className="text-md text-gray-500 mb-10 font-regular">
                  Nach der kostenlosen Registrierung wird sich ein HR-Spezialist der KMU Personal AG bei Ihnen melden, um eine ideale Tätigkeit für Sie zu finden.
              </div>
              </>
            }
            <Formik
              initialValues={{
                salutation: '',
                firstName: '',
                lastName: '',
                birthdayDay: '',
                birthdayMonth: '',
                birthdayYear: '',
                mobile: '',
                email: '',
                job: '',
                isOpenForOtherJobs: false,
                comment: '',
                acceptedTerms: false,
                // saluation: 'Herr',
                // firstName: 'Max',
                // lastName: 'Lange',
                // birthdayDay: '4',
                // birthdayMonth: '12',
                // birthdayYear: '1978',
                // mobile: '07970149',
                // email: 'max@hotmail.ch',
                // job: 'Bäcker und Maler',
                // isOpenForOtherJobs: true,
                // comment: 'Keine Bemerkung',
                // acceptedTerms: true,
              }}
              onSubmit={
                (values, actions) => {
                  let applicant = {
                    salutation: values.salutation,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    birthday: new Date(values.birthdayYear, values.birthdayMonth-1, values.birthdayDay),
                    mobile: values.mobile,
                    email: values.email,
                    job: values.job,
                    isOpenForOtherJobs: values.isOpenForOtherJobs,
                    comment: values.comment
                  }


                  fetch("/", {
                    method: "POST",
                    headers: { "Content-Type": "application/x-www-form-urlencoded" },
                    body: encode({ "form-name": "registration", ...applicant })
                  })
                    .then(async () => {
                      sendEmail(applicant.salutation, applicant.firstName, applicant.lastName, applicant.birthday, applicant.mobile, applicant.email, applicant.job, applicant.isOpenForOtherJobs, applicant.comment);
                      setSuccess(true);
                      actions.resetForm();
                      window.scrollTo(0, 0);
                    })
                    .catch(() => {
                      alert('Error');
                    })
                    .finally(() => actions.setSubmitting(false))
                }
              }
              validate={values => {
                const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                const birthdayDayRegex = /^[0-9]{1,2}$/i;
                const birthdayMonthRegex = /^[0-9]{1,2}$/i;
                const birthdayYearRegex = /^[0-9]{4}$/i;
                const errors = {};
          
                if (!values.salutation) {
                  errors.salutation = 'Anrede wird benötigt'
                }
                if (!values.firstName) {
                  errors.firstName = 'Vorname wird benötigt'
                }
                if (!values.lastName) {
                  errors.lastName = 'Nachname wird benötigt'
                }
                if (!values.birthdayDay) {
                  errors.birthdayDay = 'Tag des Geburtstags wird benötigt'
                }
                if (!birthdayDayRegex.test(values.birthdayDay)) {
                  errors.birthdayDay = 'Gültiger Tag des Geburtstags wird benötigt (Beispiel: 23)'
                }
                if (!values.birthdayMonth) {
                  errors.birthdayMonth = 'Monat des Geburtstags wird benötigt'
                }
                if (!birthdayMonthRegex.test(values.birthdayMonth)) {
                  errors.birthdayMonth = 'Gültiger Monat des Geburtstags wird benötigt (Beispiel: 11)'
                }
                if (!values.birthdayYear) {
                  errors.birthdayYear = 'Jahr des Geburtstags wird benötigt'
                }
                if (!birthdayYearRegex.test(values.birthdayYear)) {
                  errors.birthdayYear = 'Gültiges Jahr des Geburtstags wird benötigt (Beispiel: 1984)'
                }
                if (!values.mobile) {
                  errors.mobile = 'Mobile wird benötigt'
                }
                if (!values.email || !emailRegex.test(values.email)) {
                  errors.email = 'Gültige E-Mail wird benötigt'
                }
                if (!values.job) {
                  errors.job = 'Angabe der gewünschten Tätigkeit wird benötigt'
                }
                if (!values.acceptedTerms === true) {
                  errors.acceptedTerms = 'Bitte lesen und akzeptieren Sie die Datenschutzerklärung'
                }
                return errors;
              }}
            >
              {() => (
                <>
                  {(!isTransmitted && !isTOSvisible && !success) &&
                    <Form>
                      <input type="hidden" name="form-name" value="registration" />
                      <div className="space-y-6">

                        <div className="col-span-6 sm:col-span-3">
                          <label htmlFor="salutation" className="block text-sm font-medium text-gray-700">Anrede</label>
                          <Field as="select" id="salutation" name="salutation" className="mt-1 block w-full py-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                            <option value="" disabled>Bitte auswählen</option>
                            <option value="Herr">Herr</option>
                            <option value="Frau">Frau</option>
                            <option value="Keine Angabe">Keine Angabe</option>
                          </Field>
                          <ErrorMessage component="div" className="text-red-600 text-sm pt-1" name="salutation" />
                        </div>

                        <div>
                          <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">Vorname</label>
                          <Field
                            type="text"
                            name="firstName"
                            id="firstName"
                            autoComplete="given-name"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full py-3 shadow-sm sm:text-sm border-gray-300 rounded-md"
                            placeholder=""
                          />
                          <ErrorMessage component="div" className="text-red-600 text-sm pt-1" name="firstName" />
                        </div>

                        <div>
                          <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Nachname</label>
                          <Field
                            type="text"
                            name="lastName"
                            id="lastName"
                            autoComplete="family-name"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full py-3 shadow-sm sm:text-sm border-gray-300 rounded-md"
                            placeholder=""
                          />
                          <ErrorMessage component="div" className="text-red-600 text-sm pt-1" name="lastName" />
                        </div>

                        <div>
                          <label htmlFor="birthday" className="block text-sm font-medium text-gray-700">Geburtstag</label>
                          <div className="grid grid-cols-4 sm:grid-cols-10 gap-2">
                            <Field
                              type="text"
                              name="birthdayDay"
                              id="birthdayDay"
                              autoComplete="bday-day"
                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full py-3 shadow-sm sm:text-sm border-gray-300 rounded-md"
                              placeholder=""
                              pattern="[0-9]*"
                              maxlength="2"
                            />
                            <Field
                              type="text"
                              name="birthdayMonth"
                              id="birthdayMonth"
                              autoComplete="bday-month"
                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full py-3 shadow-sm sm:text-sm border-gray-300 rounded-md"
                              placeholder=""
                              pattern="[0-9]*"
                              maxlength="2"
                            />
                            <Field
                              type="text"
                              name="birthdayYear"
                              id="birthdayYear"
                              autoComplete="bday-year"
                              className="col-span-2 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full py-3 shadow-sm sm:text-sm border-gray-300 rounded-md"
                              placeholder=""
                              pattern="[0-9]*"
                              maxlength="4"
                            />
                          </div>
                          <ErrorMessage component="div" className="text-red-600 text-sm pt-1" name="birthdayDay" />
                          <ErrorMessage component="div" className="text-red-600 text-sm pt-1" name="birthdayMonth" />
                          <ErrorMessage component="div" className="text-red-600 text-sm pt-1" name="birthdayYear" />
                        </div>

                        <div>
                          <label htmlFor="mobile" className="block text-sm font-medium text-gray-700">Mobile</label>
                          <Field
                            type="tel"
                            name="mobile"
                            id="mobile"
                            autoComplete="mobile"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full py-3 shadow-sm sm:text-sm border-gray-300 rounded-md"
                            placeholder=""
                          />
                          
                          <ErrorMessage component="div" className="text-red-600 text-sm pt-1" name="mobile" />
                        </div>

                        <div>
                          <label htmlFor="email" className="block text-sm font-medium text-gray-700">E-Mail</label>
                          <Field
                            type="text"
                            name="email"
                            id="email"
                            autoComplete="email"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full py-3 shadow-sm sm:text-sm border-gray-300 rounded-md"
                            placeholder=""
                          />
                          <ErrorMessage component="div" className="text-red-600 text-sm pt-1" name="email" />
                        </div>

                        <div>
                          <label htmlFor="job" className="block text-sm font-medium text-gray-700">Gewünschte Tätigkeit</label>
                          <Field
                            type="text"
                            name="job"
                            id="job"
                            autoComplete="job"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full py-3 shadow-sm sm:text-sm border-gray-300 rounded-md"
                            placeholder=""
                          />
                          <ErrorMessage component="div" className="text-red-600 text-sm pt-1" name="job" />

                          <div className="flex mt-2">
                            <Field type="checkbox" id="isOpenForOtherJobs" name="isOpenForOtherJobs" className="h-5 w-5 text-blue-600 focus:ring-indigo-500 border-gray-300 rounded" />
                            <label htmlFor="isOpenForOtherJobs" className="ml-2 block text-sm text-gray-700">
                              Ich bin auch offen für andere Tätigkeiten
                        </label>
                          </div>
                        </div>

                        <div>
                          <label htmlFor="job" className="block text-sm font-medium text-gray-700">Bemerkung (optional)</label>
                          <Field
                            as="textarea"
                            name="comment"
                            id="comment"
                            rows="3"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full py-3 shadow-sm sm:text-sm border-gray-300 rounded-md"
                            placeholder=""
                          />
                        </div>

                        <div>
                        <div className="flex items-start">
                          <div className="flex items-center h-5">
                            <Field type="checkbox" id="acceptedTerms" name="acceptedTerms" className="h-5 w-5 text-blue-600 border-gray-300 rounded" />
                          </div>
                          <div className="ml-3 text-sm">
                            <label className="font-medium text-gray-700">Ich habe die <span className="text-indigo-600 cursor-pointer" onClick={showTOS}>Datenschutzerklärung</span> gelesen und bin mit deren Geltung einverstanden</label>
                          </div>
                        </div>
                        <ErrorMessage component="div" className="text-red-600 text-sm pt-1" name="acceptedTerms" />
                        </div>
                      </div>

                      <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                        <div className="rounded-md shadow">
                          <button className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 md:py-3 md:text-md md:px-9" type="submit" value="Submit">
                            Anmelden
                      </button>
                        </div>
                      </div>

                    </Form>
                  }
                </>
              )}
            </Formik>
          </>

        </div>
      </div>
    </div>
  );
}

export default SignUpForm;
