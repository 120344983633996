import React from 'react';
//import logo from './logo.svg';
import './App.css';
import SignUpForm from './components/SignUpForm'

function App() {
  return (
   <SignUpForm />
  );
}

export default App;
